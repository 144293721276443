<template>
  <div class="meeting-apply-page wrapper page-wrap">
    <a-spin :spinning="loadingForm">
      <a-row type="flex" justify="space-between" v-if="meeting" :gutter="40">
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">房间名称</div>
            <div class="info-content">{{ meeting.meeting_name }}</div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">房间地址</div>
            <div class="info-content">
              {{ meeting.meeting_address }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">房间管理员</div>
            <div class="info-content">{{ meeting.meeting_contact_name }}</div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">房间联系电话</div>
            <div class="info-content">{{ meeting.meeting_contact_phone }}</div>
          </div>
        </a-col>
        <a-col :span="24">
          <div class="flex flex-between info">
            <div class="info-title">预定说明</div>
            <div class="info-content">{{ meeting.meeting_introduction }}</div>
          </div>
        </a-col>
      </a-row>
      <div class="line"></div>
      <a-form :model="form" ref="formRef" :colon="colon" @finish="handleSubmit">
        <div class="meeting-wrap">
          <a-row type="flex" justify="space-between" :gutter="20">
            <a-col :span="12">
              <a-form-item
                name="book_contact_name"
                label="预订联系人"
                :rules="{
                  required: true,
                  message: '请输入'
                }"
              >
                <a-input
                  v-model:value="form.book_contact_name"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="book_contact_phone"
                label="联系电话"
                :rules="{
                  required: true,
                  message: '请输入联系电话'
                }"
              >
                <a-input
                  v-model:value="form.book_contact_phone"
                  size="large"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="book_enterprise_name"
                label="企业名称"
                :rules="{
                  required: true,
                  message: '请输入企业名称'
                }"
              >
                <a-input
                  v-model:value="form.book_enterprise_name"
                  size="large"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="book_date"
                label="预订日期"
                :rules="{
                  required: true,
                  message: '请选择预订日期'
                }"
              >
                <a-date-picker
                  v-model:value="form.book_date"
                  size="large"
                  show-time
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  style="width: 100%"
                  @ok="getMeetingBookTimeListByDateFun"
                />
              </a-form-item>
            </a-col>
            <a-col
              :span="24"
              v-if="meeting_book.length > 0"
              style="padding: 0 20px"
            >
              <div class="tips">注意：以下时间已被预订，请不要选择冲突时间</div>
              <div class="flex mb-20">
                <div
                  class="disabled-choose"
                  v-for="(item, index) in meeting_book"
                  :key="index"
                >
                  {{ item.book_start_time }}至{{ item.book_end_time }}
                </div>
              </div>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="book_start_time"
                label="开始时间"
                :rules="{
                  required: true,
                  message: '请选择开始时间'
                }"
              >
                <a-time-picker
                  v-model:value="form.book_start_time"
                  size="large"
                  format="HH:mm:ss"
                  valueFormat="HH:mm:ss"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="book_end_time"
                label="结束时间"
                :rules="{
                  required: true,
                  message: '请选择结束时间'
                }"
              >
                <a-time-picker
                  v-model:value="form.book_end_time"
                  size="large"
                  format="HH:mm:ss"
                  valueFormat="HH:mm:ss"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item class="">
                <a-button
                  block
                  size="large"
                  type="primary"
                  html-type="submit"
                  class="btn"
                  :loading="loadingBtn"
                  @click="handleSubmit"
                >
                  提交审核
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </a-form>
    </a-spin>
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref
} from "@vue/runtime-core";
import {
  insertMeetingBookData,
  getMeetingData,
  getMeetingBookTimeListByDate
} from "@/api/main.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import url from "@/utils/url.js";
import { message } from "ant-design-vue";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "MeetingApply",
  components: {},
  setup() {
    const formRef = ref();
    const route = useRoute();
    const pageData = reactive({
      colon: false,
      autosize: false,
      meetingId: null,
      form: {
        book_contact_name: null,
        book_contact_phone: null,
        book_date: null,
        book_end_time: null,
        book_start_time: null
      },
      loadingBtn: false,
      loadingForm: false,
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData()),
      meeting: null,
      meeting_book: []
    });
    const getMeetingDataFun = _meetingId => {
      getMeetingData({
        meeting_id: _meetingId,
        park_id: pageData.userData?.user_park_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.meeting = res.data.meeting;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const getMeetingBookTimeListByDateFun = e => {
      getMeetingBookTimeListByDate({
        meeting_id: pageData.meetingId,
        book_date: e,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.meeting_book = res.data.meeting_book;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const handleSubmit = e => {
      e.preventDefault();
      formRef.value
        .validate()
        .then(res => {
          pageData.loadingBtn = true;
          pageData.loadingForm = true;
          insertMeetingBookData({
            ...res,
            user_id: pageData.user_id,
            meeting_id: pageData.meetingId
          }).then(res => {
            message.success("提交成功");
            url.navigateTo("/meeting/record");
          });
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/meeting/apply") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.meetingId = query[0];
            getMeetingDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.meeting = null;
            message.error("暂无会议室信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    return {
      ...toRefs(pageData),
      formRef,
      handleSubmit,
      getMeetingBookTimeListByDateFun
    };
  }
});
</script>
<style lang="less">
.meeting-apply-page {
  margin-top: 40px;
  .info {
    border-bottom: 1px solid #dfdfdf;
    height: 60px;
    .info-title {
      color: #212531;
      opacity: 0.7;
      font-size: 18px;
    }
    .info-content {
      color: #212531;
      font-size: 18px;
      span {
        width: 70px;
        height: 31px;
        background-color: #1276cb;
        border-radius: 15px;
        color: #ffffff;
        padding: 0 10px;
        margin-left: 10px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
  .line {
    background-color: #f3f5f8;
    height: 10px;
    margin-top: 30px;
  }
  .meeting-wrap {
    width: 960px;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
  .line {
    height: 10px;
    background-color: #f3f5f8;
    margin-bottom: 20px;
  }
  .tips {
    font-size: 16px;
    margin-bottom: 15px;
    color: red;
  }
  .disabled-choose {
    width: 120px;
    height: 60px;
    border-radius: 10px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
</style>
